export const extractUrlParams = () => {
  const urlSearchParams = new URLSearchParams(location.search)

  const obj = {}

  urlSearchParams.forEach((value, key) => {
    switch (key) {
      case 's1':
        obj['sub_id1'] = checkForPlaceholder(value) || '1'
        break
      case 'sub2':
        obj['sub_id2'] = checkForPlaceholder(value)
        break
      case 'sub3':
        obj['sub_id3'] = checkForPlaceholder(value)
        break
      case 'sub4':
        obj['sub_id4'] = checkForPlaceholder(value)
        break
      default:
        obj[key] = checkForPlaceholder(value)
    }
  })

  if (obj['sub1']) {
    obj['sub_id1'] = obj['sub1'] || '1'
  }

  if (obj['sub_id']) {
    obj['sub_id1'] = obj['sub_id'] || '1'
  }

  if (obj['sub5']) {
    obj['click_id'] = obj['sub5']
    delete obj['sub5'];
  }

  return obj;
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
const checkForPlaceholder = text => /^{.+}$/g.test((text || '').trim()) ? '' : text

export const getEverflow = () => {
  return new Promise(resolve => {
    const offId = 0

    const interval = setInterval(() => {
      if (window.EF) {
        const urlParams = new URLSearchParams(window.location.search);
        EF.click({
          offer_id: EF.urlParameter('oid') || offId,
          affiliate_id: EF.urlParameter('affid') || 1,
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          source_id: EF.urlParameter('source_id'),
          transaction_id: EF.urlParameter('_ef_transaction_id'),
        }).then(tid => {
          if (tid) {
            urlParams.set('_ef_transaction_id', tid)
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
            resolve({ ef_transaction_id : tid })
          }
        })
        clearInterval(interval)
      }
    }, 5)
  })
}

export const getRetreaverNumber = () => {
  return new Promise((resolve) => {
    //TODO: Need to change campaign number in index.html file for retreaver (currently using campaign key from AutoAccidentTeam)
    const interval = setInterval(() => {
      if (window.RetreaverNumber) {
        console.log('hi')
        clearInterval(interval)
        resolve({
          retreaverNumber: window.RetreaverNumber.get('number'),
          retreaverNumberFormatted: window.RetreaverNumber.get('formatted_number')
        })
      }
    }, 5)
  })
}

export const getJornayaLeadIdToken = () => {
  return new Promise(res => {
    const i = setInterval(() => {
      const input = document.getElementById("leadid_token")
      let val = ''
      if (input) {
        val = input.value
      }
      if (val) {
        clearInterval(i)
        res(val)
      }
    }, 5)
  })
}

export const getTrustedForm = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const trustedForm = setTrustedForm()
      if (trustedForm.trustedFormUrl.length > 0) {
        clearInterval(interval)
        resolve(trustedForm)
      }
    }, 5)
  })
}

const setTrustedForm = () => {
  let trustedFormUrl = '';
  let trustedFormId = '';
  if (document.getElementById('xxTrustedFormCertUrl_0')) {
    const tfCertUrl = document.getElementById('xxTrustedFormCertUrl_0').value
    const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
    trustedFormUrl = tfCertUrl;
    trustedFormId = tfParts[1];
  }
  return {
    trustedFormUrl,
    trustedFormId
  }
}

const getWindowOrientation = () => {
  return window.innerWidth > window.innerHeight ? "Landscape" : "Portrait"
}

const isInViewport = (element) => {
  const rect = element.getBoundingClientRect()

  return (
    rect.top >=0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const scrollToElement = ({
  landScapeSelector,
  landScapeOffset = 0,
  landScapeBehavior = "smooth",
  portraitSelector,
  portraitOffset = 0,
  portraitBehavior = "smooth",
}) => {
  const orientation = getWindowOrientation()
  const landscapeElement = document.querySelector(`${landScapeSelector}`)
  const portraitElement = document.querySelector(`${portraitSelector}`)

  if (orientation === "Portrait") {
    if (isInViewport(portraitElement)) return

    window.scrollTo({
      top: portraitElement.offsetTop - portraitOffset,
      behavior: portraitBehavior,
    })
  } else {
    if (isInViewport(landscapeElement)) return
    
    window.scrollTo({
      top: landscapeElement.offsetTop - landScapeOffset,
      behavior: landScapeBehavior,
    })
  }
}
