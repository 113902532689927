import { createApp } from 'vue'
import router from '@/routes/index'
import store from '@/store/index'
import App from './App.vue'
import '@/assets/styles/index.scss'

createApp(App)
  .use(router)
  .use(store)
  .mount('#app')
