import axios from 'axios'
import questionnaire from './modules/questionnaire'
import questionnaire_v2 from '@/store/modules/questionnaire_v2'
import quote_way from '@/store/modules/quote-way'
import { createStore } from 'vuex'
import { extractUrlParams } from '@/util/getters'

export default createStore({
  modules: {
    questionnaire,
    questionnaire_v2,
    quote_way
  },
  state: {
    fields: {
      affid: 1,
      sub_id: ''
    },
    loading: false,
    currentStep: null,
    progress: 0
  },
  mutations: {
    SET_IS_LOADING(state, loading) {
      state.loading = loading
    },

    SET_FIELD(state, {field, value}) {
      state.fields[field] = value
    },

    SET_FIELDS(state, array_of_fields) {
      array_of_fields.forEach(data => {
        state.fields[data.field] = data.value
      })
    },

    SET_CURRENT_STEP(state, step) {
      state.currentStep = step
    },

    SET_CURRENT_PROGRESS(state, progress) {
      state.progress = progress
    },

    SET_FIELDS_FROM_URL(state) {
      const urlParams = extractUrlParams()
      for (const [key, value] of Object.entries(urlParams)) {
        state.fields[key] = value
      }
    }
  },
  actions: {
    async sendDataToLp({state}) {
      const url = 'https://control.org31415.dev/api/leads/ingest'
      await axios.post(url, state.fields)
    }
  }
})