const quote_way = {
  namespaced: true,
  state: {
    showLeaveBehindAds: false,
  },
  mutations: {
    SET_SHOW_LEAVE_BEHIND_ADS(state, showLeaveBehindAds = true) {
      state.showLeaveBehindAds = showLeaveBehindAds
    }
  },
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      let nextStep = ''
      let progress = 0

      switch(step) {
        case 'StartPath': {
          nextStep = 'DateOfBirth'
          progress = 34
          break
        }

        case 'DateOfBirth': {
          nextStep = 'DiagnosedLastThreeYears'
          progress = 45
          break
        }

        case 'DiagnosedLastThreeYears': {
          nextStep = 'YearlyIncome'
          progress = 56
          break
        }

        case 'YearlyIncome': {
          nextStep = 'Name'
          progress = 64
          break
        }

        case 'Name': {
          nextStep = 'Email'
          progress = 75
          break
        }

        case 'Email': {
          nextStep = 'Phone'
          progress = 92
          break
        }

        case 'Phone': {
          nextStep = 'ThankYou'
          progress = 100
          dispatch('sendDataToLp', null, {root: true})
          break
        }
      }

      commit('SET_CURRENT_STEP', nextStep, {root: true})
      commit('SET_CURRENT_PROGRESS', progress, {root: true})
    }
  }
}

export default quote_way