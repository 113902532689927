<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'

const currentRoute = useRoute()
watch(currentRoute, () => {
  if ('title' in currentRoute.meta) {
    document.title = currentRoute.meta.title
  }
})
</script>


<template>
  <router-view/>
</template>
