import { createWebHistory, createRouter } from 'vue-router'

const Home = () => import (/*webpackChunkName: "Home"*/ "@/views/Home")
const PreLander = () => import (/*webpackChunkName: "PreLander"*/ "@/views/PreLander")
const QuestionnaireApp = () => import (/*webpackChunkName: "Questionnaire"*/ "@/views/QuestionnairePage")
const QuestionnaireApp_v2 = () => import (/*webpackChunkName: "QuestionnaireApp_v2"*/ "@/views/QuestionnairePage_v2")
const QuoteWayApp = () => import (/*webpackChunkName: "QuoteWayApp"*/ "@/views/QuoteWayApp")

const routes = [
  {
    path: '/',
    name: "American First Life",
    component: Home,
    meta: { title: 'American First Life' }
  },
  {
    path: '/funeral-home-article',
    name: "Funeral Home Article",
    component: PreLander,
    meta: {title: 'American First Life'}
  },
  {
    path: '/quotes-survey',
    name: "Quotes Survey",
    component: QuestionnaireApp_v2,
    meta: { title: 'American First Life Survey'}
  },
  {
    path: '/quote-way',
    name: 'Quote way Survey',
    component: QuoteWayApp,
    meta: { title: 'American First Life' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router