const questionnaire = {
  namespaced: true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0

      switch(step) {
        case 'StartPath': {
          nextStep = 'DoYouHaveLifeInsurance'
          progress = 34
          break
        }
        case 'DoYouHaveLifeInsurance': {
          nextStep = 'HaveYouUsedTobacco'
          progress = 37
          break
        }
        case 'HaveYouUsedTobacco': {
          nextStep = 'MarriageStatus'
          progress = 41
          break
        }
        case 'MarriageStatus': {
          nextStep = 'ChildrenStatus'
          progress = 44
          break
        }
        case 'ChildrenStatus': {
          nextStep = 'DateOfBirth'
          progress = 47
          break
        }
        case 'DateOfBirth': {
          nextStep = 'ReasonForLifeInsurance'
          progress = 52
          break
        }
        case 'ReasonForLifeInsurance': {
          nextStep = 'Height'
          progress = 55
          break
        }
        case 'Height': {
          nextStep = 'Weight'
          progress = 57
          break
        }
        case 'Weight': {
          nextStep = 'Treated_Or_Prescribed_Last_Five_Years'
          progress = 59
          break
        }
        case 'Treated_Or_Prescribed_Last_Five_Years': {
          if (rootState.fields.anxiety_depression_bipolar === 1) {
            //set is medical conditional listed since we will not display step
            commit('SET_FIELD', {field: 'is_medical_condition_listed', value: 'no'}, {root: true})
            nextStep = 'HospitalizedDueToAnxiety'
          }
          //DO NOT MAKE THIS ELSE IF, if user answers both, we want is medical condition listed to prioritize, we will NOT show both questions
          if (rootState.fields.other_medical_conditions === 1) {
            //set hospitalized_due_to_anxiety field since we will not be displaying step
            commit('SET_FIELD', {field: 'hospitalized_due_to_anxiety', value: 'no'}, {root: true})
            nextStep = 'IsMedicalConditionListed'
          } else if (rootState.fields.anxiety_depression_bipolar === 0 && rootState.fields.other_medical_conditions === 0) {
            //set both fields (hospitalized_due_to_anxiety && is_medical_condition_listed) since we will not be displaying steps
            commit(
              'SET_FIELDS',
              [
                {field: 'hospitalized_due_to_anxiety', value: 'no'},
                {field: 'is_medical_condition_listed', value: 'no'}
              ],
              {root: true})
            nextStep = 'EmploymentStatus'
          }
          progress = nextStep === 'EmploymentStatus' ? 65 : 62
          break
        }

        case 'HospitalizedDueToAnxiety':
        case 'IsMedicalConditionListed':
        {
          nextStep = 'EmploymentStatus'
          progress = 65
          break
        }

        case 'EmploymentStatus': {
          nextStep = 'Zip'
          progress = 68
          break
        }
        case 'Zip': {
          nextStep = 'Name'
          progress = 90
          break
        }
        case 'Name': {
          nextStep = 'Email'
          progress = 92
          break
        }
        case 'Email': {
          nextStep = 'Phone'
          progress = 93
          break
        }
        case 'Phone': {
          nextStep = 'ThankYou'
          progress = 100
          dispatch('sendDataToLp', null, {root: true})
          break
        }
      }

      commit('SET_CURRENT_STEP', nextStep, {root: true})
      commit('SET_CURRENT_PROGRESS', progress, {root: true})
    }
  }
}

export default questionnaire